import React from 'react'
import { AiIcon } from '@diversioteam/diversio-ds'

import * as S from './navdescription.styles'

export const LumiNavDescription = () => {
    return (
        <S.NavDescriptionWrapper>
            <S.Text>Transform job posts to attract top talent.</S.Text>
            <S.ActionText>Try Lumi Today</S.ActionText>
            <S.IconWrapper>
                <AiIcon type="bold" color="#7961FF" />
            </S.IconWrapper>
        </S.NavDescriptionWrapper>
    )
}
