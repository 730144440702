import { Kpi, MediaType, Pagination, TargetGroup } from 'types/common'

export type Catalog = {
    uuid: string
    title: string
    description: string | null
    catalogType: CatalogType
    thumbnail: string | null
    thumbnailAltText: string | null
    url: string
    [CatalogMetadataKey.ReportMetadata]: BasicMetadata | null
    [CatalogMetadataKey.ResourceMetadata]: BasicMetadata | null
    [CatalogMetadataKey.ToolMetadata]: BasicMetadata | null
    [CatalogMetadataKey.TrainingMetadata]: TrainingMetadata | null
    kpis: Kpi[] | null
    targetGroups: TargetGroup[] | null
}

export type CatalogType = 'Tool' | 'Resource' | 'Report' | 'Training'

export enum CatalogMetadataKey {
    ReportMetadata = 'reportMetadata',
    ResourceMetadata = 'resourceMetadata',
    ToolMetadata = 'toolMetadata',
    TrainingMetadata = 'trainingMetadata',
}

export type BasicMetadata = {
    id: number
    uuid: string
    category: {
        label: string
        value: string
    }
    mediaType: MediaType
    isSaved: boolean
    companyCatalogId: number | null
    isNew: boolean
    type: 'diversio'
}

export type TrainingMetadata = {
    id: number
    uuid: string
    objectives: Objective[]
    certificateProgramName: string | null
    marketingUrl: string | null
    isNew: boolean
    isPopular: boolean
    isLive: boolean
    isAsync: boolean
    isGlobal: boolean
    category: {
        label: string
        value: string
    }
    trainingLevel: TrainingLevel
    isSaved: false
    companyCatalogId: number | null
}

export type Objective = {
    title: string
    details: string[]
}

export type CatalogMetadata = BasicMetadata | TrainingMetadata

export type GetCatalogsResponse = {
    results: Catalog[]
} & Pagination

export type GetCatalogsParams = {
    search?: string
    page?: number
    page_size?: number
    kpis?: string[]
    resource_types?: string[]
}

export type GetMyCatalogsResponse = {
    uuid: string
    catalog: Catalog
}[]

export type SaveCatalogResponse = {
    id: number
    catalog: number
    company: number
    isActive: boolean
}

export type GetCatalogFiltersResponse = {
    filters: CatalogFilter[]
}

export type CatalogFilter = {
    label: string
    name: 'resource_types' | 'kpis' | 'training_level'
    type: 'singleselect' | 'multiselect'
    options: CatalogFilterOption[]
}

export type CatalogFilterOption = {
    label: string
    value: string | number
}

export type TrainingLevel = 'Fundamentals' | 'Intermediate' | 'Advanced'
