import React from 'react'
import { DownloadIcon, InclusionScoresOverTime, OnboardingButton, Tooltip } from '@diversioteam/diversio-ds'
import { ErrorBoundary } from '@sentry/react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useExportInclusionDataOverTime } from 'hooks/inclusion/export/useExportInclusionDataOverTime'
import { InclusionDataProps, useInclusionScoresOverTime } from 'hooks/useInclusionScoresOverTime'
import { TooltipId } from 'utils/tooltips.enum'
import { useTooltips } from 'hooks/tooltips/useTooltips'

import { useFilters } from './../../hooks/useFilters'
import { DataRepresentationOverTimeProps } from './dataRepresentationOverTime.types'
import * as S from './dataRepresentationOverTime.styles'

const DataRepresentationOverTimeWithoutEB = ({ selectedTab }: DataRepresentationOverTimeProps) => {
    const { selectedFilters, selectedSurveysFilter } = useFilters()

    const { getOnboardingButtonProps } = useTooltips()

    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.InclusionResultsOverTime)

    const inclusionDataParams: InclusionDataProps = {
        ...selectedFilters,
        years: selectedSurveysFilter,
        key: selectedTab,
    }

    const { data, isLoading } = useInclusionScoresOverTime({
        ...inclusionDataParams,
    })

    const mutationExportInclusionDataOverTime = useExportInclusionDataOverTime({
        ...inclusionDataParams,
        export_format: 'csv',
    })

    if (isLoading || !data) {
        return null
    }

    return (
        <S.DataRepresentationOverTimeWrapper>
            <S.Header>
                <S.Title>
                    Inclusion Trends
                    <Tooltip title="CSV Download">
                        <S.DownloadButton
                            color="transparent"
                            size="xsmall"
                            iconOnly
                            loading={mutationExportInclusionDataOverTime.isLoading}
                            onClick={mutationExportInclusionDataOverTime.mutate}
                            aria-label="Download"
                        >
                            <DownloadIcon type="line" />
                        </S.DownloadButton>
                    </Tooltip>
                </S.Title>
                {onboardingButtonProps && <OnboardingButton placement="bottom-end" {...onboardingButtonProps} />}
            </S.Header>

            <ErrorBoundary>
                <InclusionScoresOverTime data={data} />
            </ErrorBoundary>
        </S.DataRepresentationOverTimeWrapper>
    )
}

export const DataRepresentationOverTime = withErrorBoundary(DataRepresentationOverTimeWithoutEB, {})
