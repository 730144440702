import styled from 'styled-components'
import { Button, getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const BarChartCardWrapper = styled.div`
    padding: 30px 38px 20px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    position: relative;

    .dds-onboarding-button {
        position: absolute;
        top: 12px;
        right: 12px;
    }
`

export const BarChartHeader = styled.header`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 24px;

    ${media.md} {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
            'topLeft topRight'
            'bottom bottom';
        grid-row-gap: 16px;
    }
`

export const DownloadButton = styled(Button)`
    &.dds-system-button div[class*='-icon'] {
        background-color: ${({ theme }) => theme.colors.primary90};
    }
`

export const BarChartTitle = styled.h2`
    display: flex;
    gap: 12px;
    grid-area: topLeft;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('cardHeaderBold')};
`

export const LegendList = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
`

export const BenchmarkLegendList = styled(LegendList)`
    grid-area: topRight;
    gap: 12px;

    ${media.md} {
        justify-content: flex-end;
    }
`

export const GroupsLegendList = styled(LegendList)`
    grid-area: bottom;
    gap: 24px;
`

export const LegendItem = styled.li`
    display: flex;
    align-items: center;
`

export const LegendItemLabel = styled.span`
    display: flex;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('captionRegular')};
`
