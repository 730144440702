import { Button } from '@diversioteam/diversio-ds'
import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const DataRepresentationOverTimeWrapper = styled.section`
    padding: 30px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    border-radius: 15px;
`

export const Header = styled.div`
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dds-input-button {
        padding-right: 10px;
    }

    .dds-single-select {
        margin-left: auto;
    }
`

export const Title = styled.h2`
    display: flex;
    gap: 6px;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')}
`

export const DownloadButton = styled(Button)`
    max-width: 25px;
    max-height: 25px;
    &.dds-system-button div[class*='-icon'] {
        background-color: ${({ theme }) => theme.colors.primary90};
    }
`
