/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { stringify } from "query-string";
import mixpanel from "mixpanel-browser";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import {
  ProgramCell,
  ProgramsLogo,
  PeopleIcon,
  Button,
  ReloadIcon,
  GoalCard,
  ScoreCard,
  Toast,
  InsightCard,
  Carousel,
} from "@diversioteam/diversio-ds";

import { AverageImpactProgramTag } from "components/AvgImpactProgramTag";

import { ProgramCellListSkeleton } from "components/skeletons/programCellListSkeleton";
import { RecommendedCarouselSkeleton } from "components/skeletons/recomenndedCarouselSkeleton";
import { Actions } from "actions";
import { getActiveCompany, getActiveCompanyID } from "utils";
import { mapIcons } from "utils/kpiUtils";
import { getPageName } from "utils/tracking";

import { withHooks } from "config/withHooks/withHooks";
import { getCleanedKpiCodes } from "utils/getCleanedKpiCodes";
import { getCatalogMetadataKey } from "components/Catalog/catalog.helpers";
import TAXONOMIES from "utils/taxonomies";

import "./index.scss";

const PAGE_NAME = "recommended"

// https://stackoverflow.com/questions/23013573/swap-key-with-value-in-object
// Used to mimic mapping of values for create goal card, needs to be improved TODO

class Recommended extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();

    this.props.resetRecommendedPage();

    const presetKPI = props.history.location.state?.kpi;
    const presetTargetGroup = props.history.location.state?.targetGroup;
    const presetInsightPosition =
      props.history.location.state?.insightArrPosition;

    let fromGoalsPage = null;
    
    if (presetKPI && presetTargetGroup) {
      // https://stackoverflow.com/questions/40099431/how-do-i-clear-location-state-in-react-router-on-page-reload
      window.history.replaceState({}, null);
      fromGoalsPage = true;
    }

    this.state = {
      selectedImprovementScore: null,
      selectedKPI: fromGoalsPage ? presetKPI : null,
      selectedTargetGroup: fromGoalsPage ? presetTargetGroup : null,
      selectedTimeline: null,
      selectedGoalString: null,
      page: 1,
      insightArrPosition: fromGoalsPage ? presetInsightPosition : 0,
      modalOpen: false,
      newKPI: null,
      newTargetGroup: null,
      fromGoalsPage: fromGoalsPage,
    };

    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    
    this.props.resetGlobalToast();
    this.props.getRecommendedInsights();
    this.props.getGoalsOptionsV2({ id: activeID });
  }

  componentDidMount() {
    mixpanel.track(TAXONOMIES.VIEW_PAGE, {
      name: getPageName(PAGE_NAME)
    })
  }

  componentDidUpdate(prevProps) {
    /**
     * Call the backend api to load recommended programs once the webpage has loaded
     * the dashboards recommended insights
     */
    const {
      fromGoalsPage,
      selectedKPI,
      selectedTargetGroup,
      page,
      insightArrPosition,
    } = this.state;

    const prev = prevProps.solutionRecommended;
    const cur = this.props.solutionRecommended;
    const { insights } = this.props.solutionRecommended;
    const targetGroups = insights.target_groups;
    
    if (
      JSON.stringify(prev.insights.insights) != JSON.stringify(cur.insights.insights) && cur.insights.insights != undefined &&
      !prev.insights.insights?.length
    ) {
      let fromGoalsPageKpi = cur.insights.insights.filter(
        (i) => i.kpi.code === selectedKPI
      );

      if (fromGoalsPageKpi?.length) {
        fromGoalsPageKpi = fromGoalsPageKpi[0]?.kpi;

        this.ref.current?.onSlideTo(insightArrPosition);
      }

      const initialKpi = fromGoalsPage
        ? fromGoalsPageKpi
        : cur.insights.insights[0]?.kpi;
      
      const targetGroup = fromGoalsPage
        ? selectedTargetGroup
        : cur.insights.insights[0]?.targetGroup;
      
      this.setState({
        // set code to be used in mapping object for the create goal card
        selectedKPI: initialKpi?.code,
        selectedTargetGroup: targetGroup,
      });

      const tg_array = [];

      if (targetGroups) {
        tg_array.push(targetGroups[targetGroup].id);
      }
  
      const query = stringify({
        kpis: [initialKpi?.id],
        target_groups: tg_array,
        page: page,
        page_size: 10,
      });

      this.props.getRecommendedPrograms(query);
    }
  }

  handleLoadMoreRecommendedPrograms = () => {
    /**
     * Function to call the backend api and load the recommended programs with the selected kpi provided
     * Will also include selected target group for filtering in the future
     */
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.LOAD_MORE_PROGRAMS}`)

    const { insightArrPosition, page, selectedTargetGroup } = this.state;
    const { insights } = this.props.solutionRecommended;
    const newPage = page + 1;
    const kpiID = insights.insights[insightArrPosition].kpi.id;
    const targetGroups = insights.targetGroup;
    const tg_array = [];

    tg_array.push(targetGroups[selectedTargetGroup].id);

    this.setState({
      page: newPage,
    });

    const query = stringify({
      kpis: [kpiID],
      target_groups: tg_array,
      page: newPage,
      page_size: 10,
    });

    this.props.getMoreRecommendedPrograms(query);
  };

  handleModalOpen = () => {
    this.setState({
      modalOpen: true,
      newKPI: null,
      newTargetGroup: null,
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
      newKPI: null,
      newTargetGroup: null,
    });
  };

  onCreate = async (originalScore) => {
    /**
     * Called to create a new goal based off the user selected values
     */
    const {
      selectedImprovementScore,
      selectedKPI,
      selectedTargetGroup,
      selectedTimeline,
      newKPI,
      newTargetGroup,
    } = this.state;

    const { insights } = this.props.solutionRecommended
    const { mapping } = this.props.solutionGoals.insightData;
    const { options } = this.props.solutionGoals;
    const { kpiValues } = options;

    const valueCheck = [
      {
        label: "Please select an Improvement Score before continuing!",
        value: selectedImprovementScore,
      },
      {
        label: "Please select a KPI before continuing!",
        value: selectedKPI,
      },
      {
        label: "Please select a Target Group before continuing!",
        value: selectedTargetGroup,
      },
      {
        label: "Please select a Timeline before continuing!",
        value: selectedTimeline,
      },
    ];

    // Check to see if any required values were not selected
    for (const i in valueCheck) {
      const row = valueCheck[i];
      if (!row.value) {
        this.props.showToast({ type: "error", message: row.label });
        return;
      }
    }

    // These 2 values are temporary
    let kpiID = kpiValues.filter((kpi) => kpi.code === selectedKPI)[0].id;

    if (newKPI) {
      // use new kpi
      kpiID = kpiValues.filter((kpi) => kpi.code === newKPI)[0].id;
    }

    const targetGroups = insights.targetGroup;
    const tg_array = [];

    tg_array.push(targetGroups[newTargetGroup || selectedTargetGroup].id);
  
    const newGoal = {
      point_improvement: selectedImprovementScore,
      kpis: [kpiID],
      target_groups_v2: tg_array,
      duration: parseFloat(options.timelineMapping[selectedTimeline]),
      survey: insights.surveyId,
      original_score: originalScore?.toFixed(2),
    };

    const selectedKpiCode = mapping.score_card_mapping[selectedKPI];

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.FINISHED_CREATING_GOAL_FROM_BANNER}`, {
      pointImprovementChoice: selectedImprovementScore,
      kpiChoice: selectedKpiCode,
      targetGroupChoice: selectedTargetGroup,
      timeFrameChoice: selectedTimeline
    })

    await this.props.doCreateGoal(newGoal);
  };

  handleParent = (key, value) => {
    /**
     * Function to be called to update the goal card's parent state
     */
    switch (key) {
      case "pointImprovement":
        return this.setState({
          selectedImprovementScore: parseFloat(value),
        });
      case "kpi":
        return this.setState({
          newKPI: value,
          selectedImprovementScore: null,
        });
      case "targetGroup":
        return this.setState({
          newTargetGroup: value,
          selectedImprovementScore: null,
        });
      case "timeline":
        return this.setState({
          selectedTimeline: value,
        });
    }
  };

  saveProgram = (row) => {
    this.props.doSaveRecommendedProgramV2({
      solution: row.id
    });
  };

  removeCompanySolution = (row) => {
    this.props.doRemoveRecommendedSolutionV2({
      id: row.companySolution.id
    });
  };

  changeSolutionStatus = (row, isActive) => {
    this.props.doChangeRecommendedSolutionStatus({
      id: row?.companySolution?.id,
      is_active: isActive
    });
  };

  createInsightCards = (insights) => {
    const cards = [];

    const handleCreateGoal = (insightName, insightIndex) => {
      mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CREATE_GOAL_FROM_BANNER}`, {
        insightName,
        insightIndex  
      })

      this.handleModalOpen()
    }

    for (const i in insights) {
      const insightCount = parseInt(i) + 1;
      const cleanedTitle = insights[i].title

      cards.push(
        <InsightCard
          index={insightCount}
          isGoalCreated={insights[i].goalCreated}
          onCreateGoal={() => handleCreateGoal(insightCount, cleanedTitle)}
          title={cleanedTitle}
        />
      );
    }
    return cards;
  };

  handleCardChange = (index) => {
    const { insights } = this.props.solutionRecommended;
    const { page } = this.state;

    let newInsight = insights.insights[index];
    
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.TOGGLE_INSIGHTS}`)

    this.props.endOfRecommendedPrograms(false);

    this.setState({
      insightArrPosition: index,
      page: 1,
      selectedKPI: insights.insights[index].kpi.code,
      selectedTargetGroup: insights.insights[index].targetGroup,
      selectedImprovementScore: null,
      selectedTimeline: null,
      newKPI: null,
      newTargetGroup: null,
    });

    newInsight = insights.insights[index];

    const kpiID = newInsight.kpi.id;
    const targetGroup = newInsight.targetGroup;

    const targetGroups = insights.targetGroup;
    const tg_array = [];

    tg_array.push(targetGroups[targetGroup].id);

    const query = stringify({
      kpis: [kpiID],
      target_groups: tg_array,
      page: page,
      page_size: 10,
    });

    this.props.getRecommendedPrograms(query);
  };

  handleSeeAll = () => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.SEE_ALL_SOLUTIONS}`)

    this.props.history.push("/programs")
  }

  render() {
    const {
      modalOpen,
      selectedImprovementScore,
      selectedKPI,
      selectedTargetGroup,
      selectedTimeline,
      newKPI,
      newTargetGroup,
    } = this.state;

    const { metadata, solutionRecommended } = this.props;

    const {
      insights,
      programs,
      goalCreated,
      endOfRecommendedPrograms,
      isLoading,
      isLoadingMore,
    } = solutionRecommended;

    const companyName = getActiveCompany(metadata?.companies)?.name;
    const { insightData, options } = this.props.solutionGoals;
    const { mapping } = insightData;
    const domGroupScore = insights?.orgData?.["Dominant group"];
    const industryScore = insights?.orgData?.["Industry Average"];
    const yourScore = insights?.orgData?.["Your KPI score"];
    const groupScores = insights?.groupData;

    const renderCardData =
      insights?.orgData &&
      groupScores &&
      selectedTargetGroup &&
      Object.keys(options).length != 0 &&
      selectedKPI;

    const targetGroupScore = () => {
      let score =
        groupScores[newTargetGroup ? newTargetGroup : selectedTargetGroup][
          selectedKPI
        ];

      if (score === "INSUFFICIENT_DATA" || score === "N/A") return null;

      return score;
    };

    const filteredTargetGroups = () => {
      if (!selectedTargetGroup) return null;
      
      const filteredGroup = [];
      // If not a custom goal then we want to remove the ability to change target groups
      let targetGroupOptions = options.groups;
      
      for (const i in targetGroupOptions) {
        if (targetGroupOptions[i] === selectedTargetGroup) {
          filteredGroup.push(targetGroupOptions[i]);
        }
      }
      
      return filteredGroup;
    };

    const filteredKpis = () => {
      if (!selectedKPI) return null;

      return options.kpis?.filter(option => option === selectedKPI)
    };

    const calculateScoreUpTo = () => {
      const { selectedImprovementScore } = this.state;
      const score = renderCardData && targetGroupScore();
    
      if (score === "INSUFFICIENT_DATA" || score === "N/A") return null;
    
      return score + selectedImprovementScore;
    };

    const filteredImprovementRange = () => {
      const range =
        renderCardData &&
        options?.pointsImprovementRange.filter(
          (value) => value + targetGroupScore() <= 10
        );

      if (range?.length) return range;
      else return options.pointsImprovementRange;
    };

    const goalCardData = {
      company: companyName,
      pointImprovement: selectedImprovementScore,
      pointImprovementOptions: filteredImprovementRange(),
      // We don't need the mapping here because selectedKPI = a kpi code, while in Goals.jsx selectedKPI = _average
      kpi: newKPI ? newKPI : selectedKPI,
      kpiOptions: filteredKpis(),
      targetGroup: newTargetGroup
        ? newTargetGroup
        : selectedTargetGroup,
      targetGroupOptions: filteredTargetGroups(),
      timeline: selectedTimeline,
      timelineOptions: options.timelineRange,
      scoreUpTo: calculateScoreUpTo(),
      maxScore: 100,
      onChange: (key, value) =>
        this.handleParent(key, value),
      onClick: () => this.onCreate(targetGroupScore()),
      type: "create",
    };

    const scoreCardData = renderCardData && {
      kpi: {
        title: mapping?.kpiMapping[selectedKPI],
        icon: newKPI ? newKPI : selectedKPI,
        score: yourScore[selectedKPI],
      },
      industry: {
        score: industryScore[selectedKPI],
      },
      targetGroup: {
        category: newTargetGroup
          ? newTargetGroup
          : selectedTargetGroup,
        score: targetGroupScore(),
      },
      groupComparison: {
        score:
          domGroupScore[selectedKPI] === "INSUFFICIENT_DATA" ||
          domGroupScore[selectedKPI] === "N/A"
            ? null
            : domGroupScore[selectedKPI],
      },
    };

    const toastError = this.props.message.toastError;

    const insightCards =
      insights?.insights?.length > 0 &&
      this.createInsightCards(insights.insights);

    if (goalCreated) {
      this.props.recommendedPageResetGoalCreated();

      this.setState({
        modalOpen: false,
      });
    }

    return (
      <div className="recommended-programs-container-v2">
        {toastError?.type && (
          <div className="ds-toast-component">
            <Toast
              open={true}
              type={toastError?.type}
              message={toastError?.message}
              onClick={() => {
                this.props.resetGlobalToast();
              }}
            />
          </div>
        )}
        <div
          className={
            modalOpen
              ? "recommended-programs-wrapper blur"
              : "recommended-programs-wrapper"
          }
        >
          <div className="recommended-programs-content">
            <div className="rp-header">
              <div className="rp-carousel">
                {
                  isLoading.insights
                    ? <RecommendedCarouselSkeleton />
                    : insightCards && 
                      <Carousel
                        elements={insightCards}
                        title={`${companyName} insights`}
                        onChange={(index) => this.handleCardChange(index)}
                        ref={this.ref}
                      />
                }
              </div>
            </div>
            <div className="rp-programs-header">
              <div className="rp-header-text">
                <div className="rp-text-body">
                  <div className="rp-top-line">
                    <h1>Recommended Programs</h1>
                    <ProgramsLogo
                      height={30}
                      width={240}
                      variant="watermarkLight"
                    />
                  </div>
                  <h3>
                    Recommendations based on your organization's needs and pain
                    points
                  </h3>
                </div>
                <div className="rp-see-all">
                  <Button color="transparent" size="small" onClick={() => this.handleSeeAll()}>
                    See All
                  </Button>
                </div>
              </div>
            </div>
            {
              (isLoading.programs || isLoading.insights)
              ? <ProgramCellListSkeleton numberOfElements={10} listElementWrapperClassName="recommended-program-wrapper" />
              : <>
                  <div className="rp-programs">
                    {
                      programs.map((row) => {
                        const tools = row.catalogs?.flatMap(catalog => {
                          const catalogMetadataKey = getCatalogMetadataKey(catalog)

                          if (!catalog[catalogMetadataKey]) {
                            return []
                          }

                          return {
                            caption: catalog.title,
                            href: catalog.url,
                            icon: mapIcons(catalog[catalogMetadataKey]),
                            title: catalog[catalogMetadataKey].mediaType,
                            withBadge: catalog[catalogMetadataKey].source === "diversio",
                          };
                        });

                        const labels = row.targetGroupsV2?.map(group => ({
                          icon: <PeopleIcon type="line" />,
                          label: group.name,
                        })) || [];

                        const averageImpactDataEntries = Object.entries(row.averageImpactData);

                        const averageImpactTags = row.hasAverageImpact ? 
                          averageImpactDataEntries.flatMap(([label, value]) => value > 0 ? ({
                            label: <AverageImpactProgramTag label={label} value={value} />,
                            color: 'green'
                          }) : [])
                          : []
                        
                        const averageImpactData = Object.fromEntries(
                          averageImpactDataEntries.map(
                            ([k, v]) => [row.painPoints.find(({ kpi }) => kpi.name === k).kpi.code, v]
                          )
                        )

                        return (
                          <div className="recommended-program-wrapper" key={row.id}>
                            <ProgramCell
                              kpis={getCleanedKpiCodes(row.painPoints)}
                              programName={row.title}
                              labels={row.recommendationLabel.map(({ text }) => text)}
                              description={row.description}
                              isSaved={!!row.companySolution}
                              isAdded={row?.companySolution?.isActive}
                              onSave={() => this.saveProgram(row)}
                              onRemoveFromSaved={() => this.removeCompanySolution(row)}
                              onAddToActive={() => this.changeSolutionStatus(row, true)}
                              onRemoveFromActive={() => this.changeSolutionStatus(row, false)}
                              tools={tools}
                              programLabels={[...averageImpactTags, ...labels]}
                              averageImpactData={averageImpactData}
                            />
                          </div>
                        );
                      })
                    }
                  </div>
                  <div className="load-more-container">
                    <div className="load-more">
                      <div className="load-more-button-wrapper">
                        <Button
                          rightIcon={<ReloadIcon type="line" />}
                          type="button"
                          color="secondary"
                          onClick={() =>
                            endOfRecommendedPrograms
                              ? this.props.showToast({
                                  type: "warning",
                                  message: "No more programs available",
                                })
                              : this.handleLoadMoreRecommendedPrograms()
                          }
                          loading={isLoadingMore.programs}
                        >
                          Load More
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
            }
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalOpen}
          onClose={this.handleModalClose.bind(this)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalOpen}>
            <Box className="recommended-modal-container">
              <div className="card-container">
                {selectedKPI && selectedTargetGroup && (
                  <div className="modal-goal-card">
                    <GoalCard {...goalCardData} />
                  </div>
                )}
                <div className="modal-score-card">
                  <ScoreCard {...scoreCardData} />
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, Actions)(withHooks(Recommended));
