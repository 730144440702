import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const NavDescriptionWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        'main-text icon'
        'action-text icon';
    width: 100%;
    height: 100%;
    text-align: left;
`

export const Text = styled.text`
    grid-area: main-text;
    white-space: pre-wrap;
    color: #111;
    color: ${({ theme }) => theme.colors.primary30};
    ${getFontStyle('p03Regular')}
`

export const ActionText = styled.div`
    grid-area: action-text;
    ${getFontStyle('p03Underline')};
    margin-top: 8px;
`

export const IconWrapper = styled.div`
    grid-area: icon;
    display: flex;
    align-items: flex-end;
    justify-content: center;
`
